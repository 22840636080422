import React, { useLayoutEffect, useRef } from 'react';
import gsap from 'gsap';
import { graphql } from 'gatsby';
import Seo from '../components/Seo';
import { GatsbyImage } from 'gatsby-plugin-image';
import Blocks from '../components/Blocks';
import PortableTextBlock from '../components/PortableTextBlock';

const IndexPage = ({ data }) => {
	const imageContainer = useRef(null);

	const tl = useRef();
	useLayoutEffect(() => {
		const images = imageContainer.current.getElementsByClassName('box');
		console.log(images);

		gsap.from(images, {
			duration: 0.2,
			scale: 1.05,
			opacity: 0,
			ease: 'power4.out',
			delay: 1,
			stagger: {
				each: 0.4,
			},
		});
	});

	const page = data && data.page;
	return (
		<>
			<Seo
				metaTitle={page.seo?.metaTitle || page.title}
				shareTitle={page.seo?.shareTitle || page.title}
				metaDesc={page.seo?.metaDesc}
				shareDesc={page.seo?.shareDesc}
			/>
			<section className="flex flex-col justify-between md:min-h-screen">
				<div className="grid grid-cols-1 md:grid-cols-3 gap-4 lg:gap-8 mb-8 lg:mb-24">
					<div className="flex flex-col justify-between">
						<h1>{page.title}</h1>
					</div>
					<div className="">
						{page._rawIntro && (
							<div className="border-l border-black pl-6 prose">
								<PortableTextBlock value={page._rawIntro} />
							</div>
						)}
					</div>
					<div className="">
						{page._rawContact && (
							<div className="border-l border-black pl-6 prose">
								<PortableTextBlock value={page._rawContact} />
							</div>
						)}
					</div>
				</div>
				<div className="order-first md:order-last grid grid-cols-1 md:grid-cols-3 gap-4 lg:gap-8 mb-8 lg:mb-24">
					<div className="flex flex-col justify-between">
						<div>
							<div
								className="relative bg-black aspect-portrait overflow-hidden"
								ref={imageContainer}
							>
								{page.gallery.map((image, i) => {
									return (
										<div
											key={i}
											className="box absolute inset-0"
										>
											<GatsbyImage
												image={
													image.image.asset
														.gatsbyImageData
												}
												alt={
													image.image.asset.altText ||
													page.title + ' image'
												}
												className="h-full w-full object-cover"
											/>
										</div>
									);
								})}
							</div>
						</div>
					</div>
					<div className="md:col-start-3 flex items-end">
						{page.logo && (
							<GatsbyImage
								image={page.logo.asset.gatsbyImageData}
								alt={
									page.logo.asset.altText ||
									page.title + ' image'
								}
							/>
						)}
					</div>
				</div>
			</section>
			<Blocks blocks={page.blocks} _rawBlocks={page._rawBlocks} />
		</>
	);
};

export default IndexPage;

export const query = graphql`
	query HomeQuery {
		page: sanityHomePage {
			id
			title
			_rawIntro
			_rawContact
			gallery {
				image {
					asset {
						gatsbyImageData
					}
				}
			}
			logo {
				asset {
					gatsbyImageData
				}
			}
			seo {
				...seoFields
			}
			_rawBlocks(resolveReferences: { maxDepth: 10 })
			blocks {
				_key
				_type
				_rawContent(resolveReferences: { maxDepth: 10 })
				content {
					...blocks
				}
				blockSettings {
					desktopWidth
				}
			}
		}
	}
`;
